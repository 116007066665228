function smoothScrollTo(selector, duration = 1000, offset) {
    const $ele = typeof selector === 'string' ? $(selector) : selector;
    offset = typeof offset === 'undefined' ? ($('body').data('offset') || 0) : offset;
    $('html, body').animate({
        scrollTop: $ele.offset().top + offset
    }, duration);
}

function htmlEntities(str) {
    return str
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
}

$(function(){

    // change page title and meta
    var facDisplayName = $('.fic-name').text();
    if (facDisplayName) {
        var newTitle = [facDisplayName, 'Faculty Profile', 'Teachers College, Columbia University'].join(' | ');
        if (document.title !== newTitle) {
            document.title = newTitle;
        }
        var newTitleEnt = htmlEntities(newTitle);
        $('meta[name="title"]').attr('content', newTitleEnt);
        $('meta[property="og:title"]').attr('content', newTitleEnt);
        $('h1.sr-only').text(facDisplayName);
    }

    // tabs
    var $targets = $('.faculty-section');
    var $triggerLis = $('.faculty-select li');
    $('.faculty-select a').click(function (e) {
        var $trigger = $(this);
        var selector = $trigger.attr('href');
        var $target = $(selector);
        if ($target.length) {
            e.preventDefault();
            // remove active and set active
            $triggerLis.removeClass('active');
            $trigger.parent().addClass('active');
            $('.faculty-select a[href="' + selector + '"]').parent().addClass('active');
            $targets.removeClass('active');
            $target.addClass('active');
            // scroll
            // smoothScrollTo($target, 500);
        }
    });

    // sort articles
    var $artList = $('#related-article-list');
    if ($artList.length) {
        var $arts = $artList.children('.article-az');
        var sortList = Array.prototype.sort.bind($arts);
        sortList(function (a, b) {
            return (b.getAttribute('data-sort') || '').localeCompare(a.getAttribute('data-sort') || '');
        });
        $artList.append($arts);
    }

    // Custom Rules
    var $wrap = $('.faculty-page-wrap');
    if ($wrap.length) {
        var uni = $wrap.attr('data-uni');
        switch (uni) {
            case 'hz30':
                $wrap.find('a[href="#vitae"][data-toggle="faculty"]').text('Doctoral Students');
                $wrap.find('a[href="#articles"][data-toggle="faculty"]').text('In The News');
                $wrap.find('#articles > h3').text('In The News');
                break;
            // case 'ae2566':
                // carousel
                // break;
        }
    }
});
